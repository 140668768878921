// NOTE: This is a list of all the animations that are available in the animation library. Please refer to the animation library for more information: https://tail-animista.vercel.app/
export enum AnimationType {
    // Basic animations
    FADE_IN = "fadeIn",
    FADE_OUT = "fadeOut",
    SLIDE_IN_TOP = "slideInTop",
    SLIDE_IN_BOTTOM = "slideInBottom",
    SLIDE_IN_LEFT = "slideInLeft",
    SLIDE_IN_RIGHT = "slideInRight",

    SLIDE_OUT = "slideOut",

    // Entrance animations
    SCALE_IN_CENTER = "scaleInCenter",
    SCALE_IN_TOP = "scaleInTop",
    SCALE_IN_BOTTOM = "scaleInBottom",
    ROTATE_IN = "rotateIn",
    SWING_IN_TOP = "swingInTop",

    // Exit animations
    SCALE_OUT_CENTER = "scaleOutCenter",
    SCALE_OUT_TOP = "scaleOutTop",
    SCALE_OUT_BOTTOM = "scaleOutBottom",
    ROTATE_OUT = "rotateOut",
    SWING_OUT_TOP = "swingOutTop",

    // Attention seekers
    BOUNCE = "bounce",
    PULSE = "pulse",
    SHAKE_X = "shakeX",
    SHAKE_Y = "shakeY",
    VIBRATE = "vibrate",

    // Background animations
    KEN_BURNS = "kenBurns",
    COLOR_CHANGE = "colorChange",

    // Text animations
    TEXT_FOCUS_IN = "textFocusIn",
    TEXT_BLUR_OUT = "textBlurOut",
    TEXT_POP_UP = "textPopUp",
    TEXT_SHADOW_DROP = "textShadowDrop",
    TEXT_TRACKING_IN = "textTrackingIn",
    TEXT_TRACKING_OUT = "textTrackingOut",
    TEXT_SCALE_UP = "textScaleUp",
    TEXT_RAINBOW = "textRainbow",
    TEXT_GLITCH = "textGlitch",
    TEXT_WAVE = "textWave"
}



// NOTE: This is a function that converts the animation type to the tailwind class name.
export const convertAnimationTypeToTailwind = (animationType: AnimationType) => {
    return `animate-${animationType}`;
}

export const fromNestedObjectToObject = (nestedObject: Record<string, any>) => {
    return Object.keys(nestedObject).reduce((acc: any, key: string) => {
        acc[key] = nestedObject[key];
        return acc;
    }, {});
}

// Animation durations and timing functions
export const animationDurations = {
    fast: "300ms",
    normal: "500ms",
    slow: "700ms"
};

export const animationTimingFunctions = {
    linear: "linear",
    ease: "ease",
    easeIn: "ease-in",
    easeOut: "ease-out",
    easeInOut: "ease-in-out"
};




