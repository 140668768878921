import { useLoaderData, useRouteLoaderData } from "@remix-run/react";
import * as React from "react";
import { useTranslation } from "react-i18next";

import CheckboxGroup from "../CheckboxGroup/CheckboxGroup";
import { IOption } from "../CheckboxGroup/type";

import Button from "~/components/atoms/Button";
import { Input } from "~/components/atoms/Input";
import { SingleSelect } from "~/components/atoms/SelectBox";
import Typo from "~/components/atoms/Typo";
import PrivacyModal from "~/components/organisms/PrivacyModal";
import { Form } from "~/components/templates/form";
import useAppState from "~/stores";
import { TProjectLoaderData } from "~/types/projectLoaderData";
import { IRouteLoaderData } from "~/types/routeLoaderData";
import {
  COUNTRY_PHONE_NUMBER_DIALCODE,
  OPTIONS_CONTACT_SALE_INTERESTED,
} from "~/utilities/constants/common";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";
import {
  generateDateOptions,
  generateTimeOptions,
} from "~/utilities/helpers/dateTime";
import {
  contactSalesSchema,
  TContactSaleFormData,
} from "~/utilities/schema/contact-sale";
export interface ContactSaleFormProps {}

export default function ContactSaleForm(props: ContactSaleFormProps) {
  const { t } = useTranslation();
  const { formNotice, config } = useRouteLoaderData("root") as IRouteLoaderData;
  const { project } = useLoaderData<TProjectLoaderData>();
  const { originalDomain } = config || {};

  const { trackingTimeline } = useAppState((state) => state);

  const unitOptions: IOption[] = project?.unitTypes?.map((unit) => ({
    id: unit.slug,
    name: unit.title,
  }));

  const { appointmentTimeOptions, appointmentDateOptions } =
    React.useMemo(() => {
      const appointmentTimeOptions = generateTimeOptions("09:00", "21:00", 30);
      const appointmentDateOptions = generateDateOptions();
      return { appointmentDateOptions, appointmentTimeOptions };
    }, []);

  const defaultValues: Partial<TContactSaleFormData> = {
    interested: ["option1", "option2", "option3"],
    unitTypes: [],
    appointmentDate: appointmentDateOptions?.[0]?.id || "",
    appointmentTime: appointmentTimeOptions?.[0]?.id || "",
    formNotice: ["contactSaleFormNotice"],
  };

  return (
    <Form<TContactSaleFormData>
      useFormProps={{ mode: "onBlur", defaultValues }}
      validationSchema={contactSalesSchema}
    >
      {({ register, setValue, watch, formState: { isSubmitting, errors } }) => {
        setValue("tracking", JSON.stringify(trackingTimeline));

        return (
          <div className="flex flex-col  gap-6 !text-lg lg:gap-3">
            <div className=" flex flex-1 flex-col items-start justify-start gap-6 lg:items-start lg:gap-3">
              <CheckboxGroup
                checkboxSize="md"
                variant="enquiry"
                options={OPTIONS_CONTACT_SALE_INTERESTED}
                register={register("interested")}
                classListCheckbox="grid grid-cols-2 w-full !text-lg"
                itemClass="first:col-span-2 lg:col-span-2 col-span-1"
                wrapperClass="w-full"
              />
              <div className="flex-2 flex w-full flex-col gap-3">
                <div className="flex w-full flex-wrap items-start gap-3 lg:flex-col">
                  <div className="flex w-full flex-1 flex-col gap-1">
                    <Input
                      variant="outlined"
                      placeholder="Name"
                      id="name"
                      className="w-full flex-1 border-color bg-transparent px-4.5 py-2 text-base text-color placeholder:!text-base  placeholder:text-color placeholder:opacity-80 lg:w-full"
                      {...register("name")}
                    />
                    {errors?.name && (
                      <Typo
                        tag={ETypoTag.P}
                        variant={ETypoVariant.BODY_TITLE_14}
                        color={ETypoColor.ERROR}
                      >
                        {errors?.name?.message}
                      </Typo>
                    )}
                  </div>
                  <div className="flex w-full flex-1 flex-col gap-1">
                    <Input
                      type="email"
                      variant="outlined"
                      placeholder="Email"
                      id="email"
                      className="w-full flex-1 border-color bg-transparent px-4.5 py-2 text-base text-color  placeholder:text-color placeholder:opacity-80 lg:w-full"
                      {...register("email")}
                    />
                    {errors?.email && (
                      <Typo
                        tag={ETypoTag.P}
                        variant={ETypoVariant.BODY_TITLE_14}
                        color={ETypoColor.ERROR}
                      >
                        {errors?.email?.message}
                      </Typo>
                    )}
                  </div>
                </div>
                <div className="flex w-full flex-wrap gap-3 lg:flex-col">
                  <div className="flex-2 flex w-1/3 flex-col gap-1 lg:w-full">
                    <div className="relative  flex-1 lg:w-full">
                      <Input
                        variant="outlined"
                        type="tel"
                        placeholder="Phone"
                        id="phone"
                        className="w-full flex-1 border-color bg-transparent py-2 pl-12 pr-4.5 text-base text-color  placeholder:text-color placeholder:opacity-80 lg:w-full"
                        {...register("phone")}
                      />
                      <span className="absolute left-4 top-1/2 -translate-y-1/2 text-base text-color">
                        {COUNTRY_PHONE_NUMBER_DIALCODE}
                      </span>
                    </div>

                    {errors.phone && (
                      <Typo
                        tag={ETypoTag.P}
                        variant={ETypoVariant.BODY_TITLE_14}
                        color={ETypoColor.ERROR}
                      >
                        {errors.phone.message}
                      </Typo>
                    )}
                  </div>
                  <div className="flex flex-1 gap-3 lg:w-full">
                    <SingleSelect
                      placeholder={t("choose_date")}
                      error={errors.appointmentDate?.message}
                      data={appointmentDateOptions}
                      title=""
                      name="appointmentDate"
                      variant="fill"
                      containerClass="lg:w-full w-3/5"
                      textSelectedClass="text-sub-title-16"
                      listboxButtonClass="text-color h-full !py-2.5 w-full border-color bg-transparent"
                      placeholderProps={{
                        variant: ETypoVariant.BODY_TITLE_16,
                        className:
                          "lg:!text-sub-title-14 !text-color opacity-80 truncate",
                      }}
                      hasAnyOption={false}
                      dropdownClass="mt-2 !max-h-[250px] "
                      dropdownIconClassName="[&_svg]:fill-background [&_svg_path]:stroke-cadetGrey"
                    />

                    <SingleSelect
                      placeholder={t("choose_time")}
                      error={errors.appointmentTime?.message}
                      data={appointmentTimeOptions}
                      title=""
                      name="appointmentTime"
                      variant="fill"
                      containerClass="lg:w-full w-2/5"
                      textSelectedClass="text-sub-title-16"
                      listboxButtonClass="text-color h-full !py-2.5 w-full border-color bg-transparent"
                      placeholderProps={{
                        variant: ETypoVariant.BODY_TITLE_16,
                        className:
                          "lg:!text-sub-title-14 !text-color opacity-80 truncate",
                      }}
                      hasAnyOption={false}
                      dropdownClass="mt-2 !max-h-[250px]"
                      dropdownIconClassName="[&_svg]:fill-background [&_svg_path]:stroke-cadetGrey"
                    />
                  </div>
                </div>
              </div>
            </div>
            <CheckboxGroup
              checkboxSize="md"
              label={t("contact_sales.unit_types")}
              options={unitOptions}
              register={register("unitTypes")}
              classListCheckbox="grid grid-cols-2 lg:grid-cols-1 text-lg"
              labelClass="text-lg"
            />
            <CheckboxGroup
              checkboxSize="md"
              variant="enquiry"
              options={[
                {
                  id: "contactSaleFormNotice",
                  name: formNotice || "",
                  checkboxContainerClass: "items-start",
                },
              ]}
              register={register("formNotice")}
              classListCheckbox="grid grid-cols-1 text-color text-lg"
            />
            <div className="flex flex-col items-center justify-start gap-3">
              <Button
                type="submit"
                animation="effectMove"
                size="medium"
                className="border-color bg-color !text-body-title-16 text-background hover:border-color hover:bg-color active:hover:bg-color active:focus:bg-color lg:mt-3 lg:w-full"
                isLoading={isSubmitting}
                disabled={watch("formNotice")?.length === 0}
              >
                {t("enquiry_form.button")}
              </Button>

              <PrivacyModal>
                <Typo
                  tag="p"
                  variant={ETypoVariant.SUB_TITLE_16}
                  color={ETypoColor.TEXT}
                  className="text-color underline lg:text-header-14"
                >
                  {t("privacy_policy")}
                </Typo>
              </PrivacyModal>
            </div>
          </div>
        );
      }}
    </Form>
  );
}
