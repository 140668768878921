/**
* Generate a random number between min and max (inclusive)
*/
export const getRandomNumber = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};



export function mergeRefs<T>(
    ...refs: Array<React.Ref<T> | undefined>
): React.RefCallback<T> {
    return (value: T) => {
        refs.forEach((ref) => {
            if (typeof ref === "function") {
                ref(value);
            } else if (ref != null && typeof ref === "object") {
                (ref as React.MutableRefObject<T | null>).current = value;
            }
        });
    };
}