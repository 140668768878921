import isEmpty from "lodash/isEmpty";
import { useCallback, useEffect, useRef } from "react";
import { useHydrated } from "remix-utils/use-hydrated";

import {
  AnimationType,
  convertAnimationTypeToTailwind,
} from "./animationConfig";

import {
  useIntersectionObserver,
  UseIntersectionObserverOptions,
} from "~/hooks/useIntersectionObserver";

interface AnimatedElement {
  element: HTMLElement;
  animation: AnimationType;
  options?: ElementAnimationOptions;
}

interface UseAnimateOptions extends UseIntersectionObserverOptions {
  defaultAnimation?: AnimationType;
}

interface ElementAnimationOptions {
  duration?: number;
  delay?: number;
  easing?: string;
}

export interface UseAnimateReturn<T extends HTMLElement> {
  rootRef: null | ((node?: Element | null | undefined) => void);
  registerAnimatedElement: (
    element: HTMLElement | null,
    animation?: AnimationType,
    options?: ElementAnimationOptions
  ) => void;
  isInView: boolean;
}

export const useAnimate = <T extends HTMLElement>(
  options: UseAnimateOptions = {}
): UseAnimateReturn<T> => {
  const {
    defaultAnimation = AnimationType.FADE_IN,
    freezeOnceVisible = true,
    root,
    initialIsIntersecting,
    onChange,
    threshold = 0.5,
    rootMargin = "0px", // Changed from percentage-based margins
  } = options;

  const isClient = useHydrated();

  const animatedElements = useRef<Set<AnimatedElement>>(new Set());

  const { isIntersecting: isInView, ref: rootRef } = useIntersectionObserver({
    threshold: threshold,
    freezeOnceVisible: freezeOnceVisible,
    rootMargin: rootMargin,
    onChange,
    initialIsIntersecting,
    root,
  });

  const registerAnimatedElement = useCallback(
    (
      element: HTMLElement | null,
      animation: AnimationType = defaultAnimation,
      options?: ElementAnimationOptions
    ) => {
      if (!isClient) {
        return;
      }

      if (element) {
        // Remove existing animation if element already exists
        animatedElements.current.forEach((item) => {
          if (item.element === element) {
            animatedElements.current.delete(item);
          }
        });

        // Add new animation
        if (!element.classList.contains("opacity-0") && !isInView) {
          element.classList.add("opacity-0"); // Add opacity-0 to the element
        }
        animatedElements.current.add({ element, animation, options });
      }
    },
    [defaultAnimation, isClient, isInView]
  );

  useEffect(() => {
    if (!isClient) return;

    // if (!isInView) {
    //   animatedElements.current.forEach(({ element }) => {
    //     if (!element.classList.contains("opacity-0")) {
    //       element.classList.add("opacity-0"); // Add opacity-0 to the element
    //     }
    //   });
    // }
    if (isInView && isClient) {
      animatedElements.current.forEach(({ element, animation, options }) => {
        // Remove existing animation class first
        const animationClass = convertAnimationTypeToTailwind(animation);

        element.classList.remove(animationClass);

        // Force reflow to reset animation
        void element.offsetWidth;

        requestAnimationFrame(() => {
          element.classList.add(animationClass);
        });

        element.addEventListener("animationstart", () => {
          element.classList.remove("opacity-0");
        });

        if (options && !isEmpty(options)) {
          // By default get the animation options from tailwind config
          if (options.duration) {
            element.style.animationDuration = `${options.duration}ms`;
          }
          if (options.delay) {
            element.style.animationDelay = `${options.delay}ms`;
          }
          if (options.easing) {
            element.style.animationTimingFunction = options.easing;
          }
        }
      });
    }
  }, [isInView, isClient]);

  if (!isClient) {
    return {
      rootRef: null,
      registerAnimatedElement: () => {},
      isInView: false,
    };
  }

  return {
    rootRef,
    registerAnimatedElement,
    isInView,
  };
};
